.icon-close-icon {
	width: 8px;
	height: 6px;
}

.icon-down-arrow {
	width: 25px;
	height: 25px;
}

.icon-if-07-skype-104495 {
	width: 22px;
	height: 23px;
}

.icon-if-contact-126567 {
	width: 18px;
	height: 14px;
}

.icon-if-ic-local-phone-48-px-352510 {
	width: 30px;
	height: 12px;
}

.icon-if-icon-111-search-314478 {
	width: 16px;
	height: 16px;
}

.icon-if-office-11-809573 {
	width: 20px;
	height: 20px;
}

.icon-if-social-linkedin-216394 {
	width: 19px;
	height: 19px;
}

.icon-left-arrow {
	width: 25px;
	height: 25px;
}

.icon-lock-icon {
	width: 11px;
	height: 14px;
}

.icon-menu-icon {
	width: 13px;
	height: 10px;
}

.icon-menu-wht {
	width: 11px;
	height: 7px;
}

.icon-printer-4 {
	width: 13px;
	height: 13px;
}

.icon-right-arrow {
	width: 25px;
	height: 25px;
}

.icon-scroll-right-mobile {
	width: 7px;
	height: 5px;
}

.icon-search-icon {
	width: 10px;
	height: 10px;
}

.icon-swipe-icon {
	width: 26px;
	height: 29px;
}

