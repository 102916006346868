.section-contact-form-wrap{
    width: 310px;
    margin-left: 90px;
    
}



.section-contact-form{
    max-width: 310px;
    padding-top: 46px;
    &#dataSheetForm{
        max-width:100%;
        padding-top:0;
    }
}

.section-contact-form .input-contact-form{
    border: none;
    border-bottom: solid 1px #c8c8c7;
    outline: none;
    //margin-bottom: 15px;
    margin-bottom: 31px;
    font-size: 14px;
    //color: rgba(0, 0, 0, 0.54);
    color: #c8c8c7;
    background-color: transparent;

}


#geTinTouchStunnForm, #dataSheetForm{
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #c8c8c7;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #c8c8c7;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #c8c8c7;
}

}


.input-submit-contact-form{
    font-size: 18px;
    line-height: 1.22;
    text-align: right;
    color: white!important;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    width: 134px;
    height: 45px;
    background-color: #eca714;
    float: right;
    align-items: center;
}

.send-text{
    //padding-left: 10px;
    text-transform: capitalize;
}

.send-icon {
    margin-top: 3px;
}

.map-container{
    width: 100%;
    /*min-height: 300px;*/
    background-color: whitesmoke;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
}

.contact-footer-block-left{
    /*flex: 1.3 1 auto;*/
    background-color: #e9e9e9;
    width: 60%;
}

.contact-footer-block-right{
    /*flex: 1 1 auto;*/
    background-color: #e9e9e9;
    padding: 58px 172px  63px 93px;
    width: 40%;
    font-size: 16px;
    line-height: 1.38;
    color: #353637;



}

.map-container-mobile{
    width: 100%;
    min-height: 100%;
    background-color: whitesmoke;

}


.section-text-left-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.section-contact .people{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    font-family: sofia-pro, sans-serif;
    font-weight: 300;
    font-style: italic;
    line-height: 1.25;
    color: #353637;
    margin-top: 20px;
}

#section-nav-contact-page,#section-map-tablet{
    display: none;
}

#map1{
    height: 100%;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(0, 0, 0, 0.54);
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(0, 0, 0, 0.54);
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(0, 0, 0, 0.54);
}

#websitehny{
    display: none;
}

@media only screen and  (max-width: 1024px) {

    .row-contact{
        flex-direction: column;
        justify-content: normal;
        margin-right: 200px;
    }

    #section-map-tablet{
        display: block;
    }

    .map-container-desktop{
        display: none;
    }
    .section-contact-form{
        padding-top: 0;
    }

    .section-contact-form-wrap{
        padding: 0;
    }



    .map-container{
        /*width: 100%;*/
        min-height: 100%;
        flex-direction: column;
    }

    .contact-footer-block-left{
        width: 100%;
        height: 60%;
        flex: 1 1 auto;
    }
    .contact-footer-block-right{
        width: 100%;
        height: 40%;
    }

    .section-contact-form-wrap {
        width: 100%;

    }

    .section-contact-form {
        max-width: 100%;
        margin-top: 60px;

    }

    .section-contact-form-wrap{
        /*padding-right: 200px;*/
    }
    .contact-col-left{
        padding-right: 0;
        max-width: 100%;
        min-width: 100%;
    }
    .section-contact-form-wrap, .people{
        max-width: 414px;
    }



}

@media only screen and  (max-width: 1023px) {
    .row-contact{
        margin-right: 127px;
    }
}
@media only screen and  (max-width: 767px) {

    .section-contact-map-tablet{
        /*display: none;*/
    }

    .contact-footer-block-left{
        height: 70%;

    }
    .contact-footer-block-right{
        height: 30%;
    }
    #section-nav-contact-page{
        display: none;
    }

    .section-contact-form .input-contact-form {
        margin-bottom: 25px;
        margin-bottom: 25px;
    }
}






//**************** About page ********************************

.image-get-in-touch-about{
    max-width: 400px!important;     /*remove importtant before*/
    max-height: 388px!important;
    margin-bottom: 29px;

}



.section-img-padding-bg-left-about{

    right: 0%;
    left: 20px;
    /*bottom: 0;*/
    height: 100%;
    max-height: 65%;
    width: 100%;
    margin-right: 0px;
    background-color: #e9e9e9;
    z-index: -1;

}

.section-getIn-touch-contact{
    .col-left{
        max-width: 42%;
        margin-right: 120px;
    }

    .col-right .section-text{
        padding-right: 200px;
    }

    .col-padding{
        padding-top: 180px;
        /*margin-right: 205px;*/
        padding-bottom: 0;
    }

    .section-slider-item {
        padding-bottom: 0;
        padding-top: 0;
    }
}

.section-sub-title-about{
    font-size: 16px;
    font-weight: 300;
    font-style: italic;
    line-height: 2.13;
    color: #353637;
    font-family: sofia-pro, sans-serif;
}

.about-getInTouch-name{
    margin-bottom: 0;
    padding-bottom: 5px;
}

.section-img-padding-bg-left-another-section{
    //max-height: 33%;
}

.contact-bodyText{
    max-width: 600px
}
.rectangle-background-mobile{
    display: none;
}

.section-text-getInTouch-desktop{
    display: block;
}

.section-text-getInTouch-mobile{
    display: none;
}


@media only screen and  (max-width: 1367px) {

    .section-getIn-touch-contact .col-left {
        margin-right: 80px;
    }
    .container {
        margin-left: 80px;
    }

    .image-get-in-touch-about{

        max-width: 350px!important;     /*remove importtant before*/
        max-height: 338px!important;
        margin-bottom: 29px;

    }
}
@media only screen and  (max-width: 1124px){
    .image-get-in-touch-about {
        max-width: 300px !important; /*remove importtant before*/
        max-height: 288px !important;
    }

    .contact-bodyText {
        max-width: 350px;
    }

    .section-img-padding-bg-left-about{
        max-height: 80%;
    }
}
@media only screen and  (max-width: 1024px){

    .image-get-in-touch-about{
        max-width: 302px!important;     /*remove importtant before*/
        max-height: 290px!important;

    }
    .section-getIn-touch-contact{
        .container {
            margin-left: 80px;
        }

        .col-left{
            margin-right: 45px;
        }

    }

}



@media only screen and  (max-width: 1023px) {


    .section-getIn-touch-contact{
        .container {

            margin-left: 0px;
            padding-left: 0;
            margin-right: 0;
            padding-right: 0;
        }
        .col-right .section-text{
            padding-right: 0;
        }

        .col-right, .col-left{
            min-width: 40%;
            max-width: 40%;
            padding-right: 0;
            padding-left: 3%;
        }

        .col-right .col-padding {
            margin-right: 0px;
        }
    }

    .justify-content-between {
        -webkit-box-pack: justify;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: justify;
        justify-content: flex-start;
    }

}


@media only screen and  (max-width: 766px) {



    .image-get-in-touch-about{
        max-width: 100%!important;     /*remove importtant before*/
        /*max-height: auto!important;*/
        margin-left: auto;
        margin-right: auto;
        /*margin-bottom: 29px;*/

    }
    .section-getIn-touch-contact {

        .contact-bodyText{
            display: none;
        }
        .col-padding {
            padding-top: 0;
        }

        .section-img {
            right: 0;
        }

        ul{
            display: none;
        }
    }


    .rectangle-background-mobile{
        display: block;
        position: absolute;
        right: 0;
        left: 0;
        top: 50%;
        height: 100%;
        background-color: #e9e9e9;
        z-index: -1;
    }

    .section-text-getInTouch-desktop{
        display: none;
    }

    .section-text-getInTouch-mobile{
        display: block;
    }
}

@media only screen and  (max-width: 600px) {
    .image-get-in-touch-about{
        height: auto!important;
    }
}


@media only screen and  (max-width: 1200px) and  (min-width: 1025px) {
    .section-img-padding-bg-left-another-section{
        max-height: 15%;
    }
}


// Contact BLock
.wrap-from {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/img/content/group-19.jpg);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}