
.footer {
    background-color: #31393e;
    border: none;
}

.footer-container {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    min-height: 100%;
    width: 100%;
    padding: 48px 18px 48px;
}

.footer-logo {
    display: block;
    width: 75px;
    height: 45px;
    margin-top: 145px;
    margin-left: -33px;
}
.footer-logo-text {
    fill: #fff;
    transition: fill 0.7s;
}
.footer-logo-ico {
    fill: #5285b6;
    opacity: 0.505;
    transition: opacity 0.7s;
}

.footer-bottom {
    width: 100%;
    max-width: 403px;
    text-align: center;
    //margin-top: 30px;
    //padding: 28px 20px 0;
    padding: 0px 20px 0;
    line-height: 1.57;
    color: rgba(#fff, 0.7);
    p {
        line-height: 1.57;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.footer-bottom-links {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    font-size: 16px;
    border-bottom: 2px solid rgba(#fff, 0.28);
    margin-bottom: 30px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    li {
        display: block;
        margin-bottom: 20px;
        .footer-bottom-link-ico {
            display: flex;
            align-items: center;
            justify-content: center;
            fill: #fff;
            width: 40px; //48px;
            height: 40px; //48px;
            margin: 0 auto 10px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #5285b6;
            transition: all 0.25s;
        }
        a {
            display: block;
            &:hover {
                .footer-bottom-link-ico {
                    background-color: #5285b6*1.2;
                }
                color: #fff;
            }
        }
    }
}

.footer-bottom-link-info {
    display: block;
    text-align: center;
}