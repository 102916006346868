////
/// FlexSlider Module
/// @author Matt Bruce
/// @author Mark Griffiths
/// @group FlexSlider
////


.flexSlider {
    position: relative;
    z-index: 1;

    overflow: hidden;

    width: 100%;
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;

    -webkit-backface-visibility: hidden;/* for safari */
}
/// ## Fill Mode
/// ### Fill Mode Fill
.flexSlider.fill {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;
    overflow: hidden;

    height: 100%;
    min-height: 100%;
}
.flexSlider.fill > ul {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    height: 100%;
    min-height: 100%;
}
.flexSlider.fill > ul > li {
    height: 100%;
    min-height: 100%;
}
/*.flexSlider.fill ul li:first-of-type, .flexSlider.fill ul li:last-of-type {    
    box-shadow:0px 0 14px black;
}*/

/// ## Transition Mode
/// ### Default Slide Styles
.flexSlider {
    >ul {
        display: block;

        height: 100%;
        margin: 0;
        padding: 0;

        list-style: none;
        >li {
            height: 100%;
            max-height: 100%;

            text-align: center;
            vertical-align: top;

            background: #666666 repeat center center;
            background-size: cover;
        }
    }
}

/// ### Transition Mode Slide
.flexSlider[data-transitionMode='slide'] {
    >ul {
        position: relative;

        width: 10000%;
        >li {
            position: relative;

            display: inline-block;
            overflow: hidden;

            width: 1%;
            margin-right: -4px;
        }
    }
}

/// ### Transition Mode Fade
.flexSlider[data-transitionMode='fade'] {
    height: 100%;
    >ul {
        // height: 100%;
        >li {
            position: absolute;
            top: 0;
            left: 0;

            display: block;

            width: 100%;

            transition: opacity 1s ease 0s;

            opacity: 0;
            &[data-state*='is-active'] {
                opacity: 1;
            }
        }
    }
}

/// ## Navigation Styles
.flexSlider-nextButton,
.flexSlider-prevButton {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;

    display: none; //Not required here
    overflow: hidden;

    width: 120px;
    min-height: 100%;

    cursor: e-resize;
    text-indent: -1000px;

    opacity: .2;
}
.flexSlider-nextButton:after,
.flexSlider-prevButton:after {
    position: absolute;
    top: 50%;

    display: none;

    width: 0;
    height: 0;
    margin-top: -80px;

    content: '';

    border: 60px solid transparent;
    border-width: 80px 60px;
}
.flexSlider-nextButton:after {
    right: 20px;

    border-right-width: 0;
    border-left-color: white;
}
.flexSlider-prevButton:after {
    left: 20px;

    border-right-color: white;
    border-left-width: 0;
}
.flexSlider-prevButton {
    right: auto;
    left: 0;

    cursor: w-resize;
}
.flexSlider-nextButton:hover,
.flexSlider-prevButton:hover {
    opacity: .8;
}
.flexSlider-dots {
    position: absolute;
    z-index: 999;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    max-width: 1364px;
    margin: auto;
    padding: 0 32px;

    text-align: center;
    pointer-events: none;

    @include media('<=tabport') {
        padding: 0 16px;
    }
    @include media('<=phoneland') {
        padding: 0 8px;
    }
}
.flexSlider-dots a {
    font-family: Arial serif;
    font-size: 70px;
    line-height: 100px;

    margin: 0 4px;

    text-decoration: none;
    pointer-events: auto;

    color: rgba(255,255,255,.6);
    &:hover {
        // color: $mango;
        color: red;
    }
}
.flexSlider-dots .current,
.flexSlider-dots [data-state*='is-active'] {
    color: rgba(255,255,255,.9);
    text-shadow: 2px 2px 5px rgba(140, 140, 140, .6);
    &:hover {
        // color: $white;
        color: #ffffff;
    }
}



/* 
Animations. 
A basic 'slide-in' animation is included but you can roll-your-own.
Please ensure that you separarate your style from the animation (IE have two classes) and give your animation-only styles a classname that begins 'animate-'. The script needs to measure the dimensions of each slide as it will be once the animation is complete, it therefore temporarily removes the 'animate-' prefixed classnames when getting measuring the slide and re-applies them after.
*/
.flexSlider [class^='animate-in-'] {
    position: absolute;

    overflow: hidden;

    width: 0;
    height: 0;

    -webkit-transition: all 1400ms;
       -moz-transition: all 1400ms;
         -o-transition: all 1400ms;
            transition: all 1400ms;
}


/// @TODO Make these work from a data-attribute rather than current class
.flexSlider .current [class*='animate-in-'],
.flexSlider [data-state*='is-active'] [class*='animate-in-'] {
    position: relative;

    overflow: visible;

    width: auto;
    height: auto;
}
.flexSlider .notransition {
    margin: 0;

    -webkit-transition: none;
       -moz-transition: none;
         -o-transition: none;
            transition: none;
}
.flexSlider .animate-in-from-left {
    left: -100%;
}
.flexSlider .animate-in-from-right {
    right: -100%;
}
.flexSlider .animate-in-from-top {
    bottom: 100%;
}
.flexSlider .animate-in-from-bottom {
    top: 100%;
}
.flexSlider .current .animate-in-from-left,
.flexSlider [data-state*='is-active'] .animate-in-from-left {
    left: 0;
}
.flexSlider .current .animate-in-from-right,
.flexSlider [data-state*='is-active'] .animate-in-from-right {
    right: 0;
}
.flexSlider .current .animate-in-from-top,
.flexSlider [data-state*='is-active'] .animate-in-from-top {
    bottom: 0;
}
.flexSlider .current .animate-in-from-bottom,
.flexSlider [data-state*='is-active'] .animate-in-from-bottom {
    top: 0;
}


/*
 * jQuery FlexSlider v2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */

 
/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus  {outline: none;}
.slides,
.flex-control-nav,
.flex-direction-nav {margin: 0; padding: 0; list-style: none;} 

/* FlexSlider Necessary Styles
*********************************/ 
.flexslider {margin: 0; padding: 0;}
.flexslider .slides > li {display: none; -webkit-backface-visibility: hidden;} /* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {width: 100%; display: block;}
.flex-pauseplay span {text-transform: capitalize;}

/* Clearfix for the .slides element */
.slides:after {content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;} 
html[xmlns] .slides {display: block;} 
* html .slides {height: 1%;}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {display: block;}


/* FlexSlider Default Theme
*********************************/
.flexslider {margin: 0 0 60px; background: #fff; border: 4px solid #fff; position: relative; -webkit-border-radius: 4px; -moz-border-radius: 4px; -o-border-radius: 4px; border-radius: 4px; box-shadow: 0 1px 4px rgba(0,0,0,.2); -webkit-box-shadow: 0 1px 4px rgba(0,0,0,.2); -moz-box-shadow: 0 1px 4px rgba(0,0,0,.2); -o-box-shadow: 0 1px 4px rgba(0,0,0,.2); zoom: 1;}
.flex-viewport {max-height: 2000px; -webkit-transition: all 1s ease; -moz-transition: all 1s ease; transition: all 1s ease;}
.loading .flex-viewport {max-height: 300px;}
.flexslider .slides {zoom: 1;}

// .carousel li {margin-right: 5px}


/* Direction Nav */
.flex-direction-nav {*height: 0;}
.flex-direction-nav a {width: 30px; height: 30px; margin: -20px 0 0; display: block; background: url(images/bg_direction_nav.png) no-repeat 0 0; position: absolute; top: 50%; z-index: 10; cursor: pointer; text-indent: -9999px; opacity: 0; -webkit-transition: all .3s ease;}
.flex-direction-nav .flex-next {background-position: 100% 0; right: -36px; }
.flex-direction-nav .flex-prev {left: -36px;}
.flexslider:hover .flex-next {opacity: 0.8; right: 5px;}
.flexslider:hover .flex-prev {opacity: 0.8; left: 5px;}
.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {opacity: 1;}
.flex-direction-nav .flex-disabled {opacity: .3!important; filter:alpha(opacity=30); cursor: default;}

/* Control Nav */
.flex-control-nav {width: 100%; position: absolute; bottom: -40px; text-align: center;}
.flex-control-nav li {margin: 0 6px; display: inline-block; zoom: 1; *display: inline;}
.flex-control-paging li a {width: 11px; height: 11px; display: block; background: #666; background: rgba(0,0,0,0.5); cursor: pointer; text-indent: -9999px; -webkit-border-radius: 20px; -moz-border-radius: 20px; -o-border-radius: 20px; border-radius: 20px; box-shadow: inset 0 0 3px rgba(0,0,0,0.3);}
.flex-control-paging li a:hover { background: #333; background: rgba(0,0,0,0.7); }
.flex-control-paging li a.flex-active { background: #000; background: rgba(0,0,0,0.9); cursor: default; }

.flex-control-thumbs {margin: 5px 0 0; position: static; overflow: hidden;}
.flex-control-thumbs li {width: 25%; float: left; margin: 0;}
.flex-control-thumbs img {width: 100%; display: block; opacity: .7; cursor: pointer;}
.flex-control-thumbs img:hover {opacity: 1;}
.flex-control-thumbs .flex-active {opacity: 1; cursor: default;}

@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {opacity: 1; left: 0;}
  .flex-direction-nav .flex-next {opacity: 1; right: 0;}
}
