.section {
    position: relative;
    overflow: hidden;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        //z-index: 9599999;
        border: 0px solid #31393e;
        //transition: height 0s 0.7s, width 0s 0.7s, border-width 0.7s 0s ease-out;
        transition: height 0s 1.3s, width 0s 1.3s, border-width 1.3s 0s ease-out;
    }
    &.section-border {
        &::before {
            border-width: 40px;
            width: 100%;
            height: 100%;
            //transition: height 0s 0s, width 0s 0s, border-width 0.7s 0s ease-out;
            transition: height 0s 0s, width 0s 0s, border-width 1.3s 0s ease-out;
        }
    }
    p {
        line-height: 1.29;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &.fp-completely {
        .wrapp-section-slider {
            position: relative;
            z-index: 1;
        }
    }
}
.section-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-blend-mode: multiply;
    color: #ffffff;
    h1, h2, h3, h4, h5, h6 {
        color: #fff;
        font-size:24px;
        letter-spacing:-0.2px;
        line-height:27px;
        &.title-orange {
            color: #eca714;
            font-size:33px;
            letter-spacing:-0.3px;
            line-height:35px;
        }
    }
}
.section-bg-white {
    background-color: #ffffff;
    color: #494a4d;
}

.section-bg-eclipse {
   &::after {
       content: "";
       position: absolute;
       left: 0;
       top: 0;
       width: 100%;
       height: 100%;
   } 
}

.section-gray {
    background-color: #f3f3f3;
}

.section-scroll-next-block {
    display: none;
}

.section-sub-title {
    font-size: 13px;
    line-height: 2.62;
    font-weight: normal;
    color: #353637;
    margin-bottom: 17px;
}

.section-text-italics {
    font-size: 16px;
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #353637;
    margin-bottom: 30px;
}

.container {
    position: relative;
    z-index: 1;
    margin-left: 180px;
    display: flex;
    overflow: hidden;
    height: 100%;
    min-height: 100vh;
}
.container-c {
    margin-left: 90px;
    .col-left {
        min-width: 565px;
        max-width: 565px;
    }
}
.container-wide {
    position: relative;
    height: 100%;
    min-height: 100vh;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
}
.container-1440 {
    display: flex;
    width: 100%;
    max-width: 1440px;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
}
.container-wrap {
    flex-wrap: wrap;
}
.container-center {
    position: relative;
    z-index: 1;
    padding-left: 180px;
    padding-right: 180px;
    display: flex;
    height: 100%;
    min-height: 100%;
}
.container-not-flex {
    display: block;
}
.section-padding-container {
    padding-top: 155px;
    padding-bottom: 100px;
}
.container-no-overflow {
    overflow: initial;
}
.col-padding {
    padding-top: 150px;
    padding-bottom: 100px;
}
.row {
    position: relative;
    display: flex;
    width: 100%;
}
.row-wrap {
    flex-wrap: wrap
}
.row-height {
    height: 100%;
}

.col-left {
    margin-right: 100px;
    min-width: 486px;
    max-width: 486px;
}

.col-margin-bottom-150 {
    margin-bottom: 150px;
}

.col-center {
    text-align: center;
    width: 100%;
}

.col-l-left {
    padding-right: 50px;
    width: 100%;
    // min-width: 645px;
}
.section-text + .section-text {
    margin-top: 88px;
}
.col-l-right {
    padding-right: 242px;
}

.align-items-end {
    align-items: flex-end
}
.align-items-center {
    align-items: center
}
.align-items-start {
    align-items: flex-start
}
.justify-content-center {
    justify-content: center;
}
.justify-content-between {
    justify-content: space-between;
}

.section-img {
    position: relative;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
    }
}

.section-img-padding-l {
    img {
        position: relative;
        z-index: 1;
        display: block;
        margin-left: 30px;
    }
}
.section-img-padding-bg-bottom {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 100%;
        margin-top: -30px;
        height: 10000px;
        width: 10000px;
        background-color: #e9e9e9;
    }
    img {
        max-width: 700px;
    }
}
.section-img-padding-bg-left {
    position: absolute;
    right: 100%;
    bottom: 0;
    height: 100%;
    max-height: 180px;
    width: 100%;
    margin-right: -90px;
    background-color: #e9e9e9;
    z-index: 2;
    padding-top: 23px;
    padding-right: 33px;
    padding-bottom: 23px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    z-index: 0;
    &::before {
        content: "";
        position: absolute;
        right: 100%;
        top: 0;
        height: 100%;
        width: 10000px;
        background-color: #e9e9e9;
    }
    .section-img-title {
        max-width: 477px;
    }
}
.section-img-title {
    position: relative;
    z-index: 1;
    display: block;
    color: #353637;
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.25;
    font-size: 16px;

}

.section-img-title-sector{
    padding-right: 180px;
}
.section-img-wide {
    position: relative;
    .section-img-padding-bg-bottom {
        &::after {
            content: none;
        }
    }
}
.section-img-title-wide {
    position: relative;
    top: -50px;
    right: 24px;
    display: block;
    color: #353637;
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.25;
    background-color: #e9e9e9;
    padding: 70px 160px 70px 50px;
    text-align: right;
}

.section-text {
    position: relative;
    z-index: 2;

    p a {
        color: #5285b6;
    }
}

.col-subtitle {
    min-width: 205px;
    max-width: 205px;
    padding-right: 20px;
    padding: 10px 0;
    font-size: 13px;
    line-height: 1.4;
    font-weight: normal;
    letter-spacing: 0.3px;
    color: #788b98;
}
.col-content {
    width: 100%;
    padding-right: 400px;
    max-width: 1100px;
    h1, h2, h3, h4, p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.col {
    width: 100%;
    padding-right: 270px;
    padding-bottom: 80px;
}

.row-padding {
    padding: 120px 0 80px;
}

.section-center-text-bg {
    width: 100%;
    max-width: 632px;
    margin: 0 auto;
    color: #fff;
    line-height: 1.42;
    font-size: 24px;
    font-family: sofia-pro, sans-serif;
    img {
        display: inline-block;
        margin-bottom: 30px;
    }
}
.section-center-text-bg-t {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.89;
    letter-spacing: normal;
    text-align: center;
    color: #f5a623;
    margin-top: 30px;
}

.section-text-btn {
    margin-top: 50px;
}

.section-swiping-container {
    display: none!important;
}

.section-swiping {
    height: 100vh;
    width: 100vw;
}
.section-swiping-block {
    position: absolute;
    left: -99999px;
    top: -99999px;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.section-swipe-ico {
    position: absolute;
    right: 25px;
    top: 75px;
    z-index: 10;
    fill: #F5A623;
    line-height: 1;
    display: none;
}
.section-swipe-ico-text {
    position: relative;
    left: 2px;
    top: -3px;
    display: block;
    line-height: 1;
    letter-spacing: 0.3px;
    color: #55565a;
    text-align: center;
    font-family: sofia-pro, sans-serif;
    font-style: italic;
    font-size: 14px;
}

@media only screen and (max-width: 1024px) {
    .section-img-title-sector {
        padding-right: 100px;
    }
}

.section-contact-mobile{
    display:none;
}

@media only screen and (max-width: 1024px) {
    .section-contact-mobile{
        display:block;
    }    
}