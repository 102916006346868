
// This should be base?
body {
    font: 16px/26px Helvetica, Helvetica Neue, Arial;
    background-color:#EEEEEE;
}

html, body {
    margin:0;
}

// Typography?
h1,h2,h3,h4,h5{
    margin:.5em;
}

.white {background-color:#FFFFFF;} /* @FIXME shit style name */


// Nav?
#nav {background:rgba(0,0,0,0.5);}
nav ul li a {color:white;}

//Fixed-header
.fixed-header {position:fixed;}

// Footer
#footer {background-color:black; margin-bottom:0;}
footer {}

// flexSlider or separate banner partial somewhere?
#banner-wrap {
    min-height:788px;
    background-color:#666;
    position:relative;
}
#banner-wrap ul > li{
    /* funky text stylng that can be seen on any colour background */
    
    font-family:Georgia, serif;
    font-size:200%;
    color: #000;
    text-shadow:
        .2em .2em .5em #000,
        -1px -1px 0 #fff,  
        1px -1px 0 #fff,
        -1px 1px 0 #fff,
        1px 1px 0 #fff;
    padding-top:30px;
    
}

.flexSlider-dots {display:none;}

.banner-text {position:absolute; bottom:0; right:0; left:0; padding:20px 0; background:rgba(0,0,0,0.5);}

/* example custom animations */
.flexSlider .current [class*="animate-in-"].animate-custom-a {
    -webkit-transition-delay: 1000ms; 
    transition-delay: 1000ms;
    color:#990000;
}
.flexSlider .current [class*="animate-in-"].animate-custom-b {
    -webkit-transition-delay: 400ms; 
    transition-delay: 400ms;
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg);
}
.flexSlider [class*="animate-in-"].animate-custom-c {
    font-size:0%;
}
.flexSlider .current [class*="animate-in-"].animate-custom-c {
    -webkit-transition-delay: 600ms; 
    transition-delay: 600ms;
    color:#333;
    font-size:100%;
}
/* example media-query */
@media only screen and (max-width : 400px) {
    #banner-wrap {
        border:0px solid green;
        min-height:0;
        height:200px!important;
        max-height:200px!important;
    }
}

.srtrevor-container{
  .content {
    max-width: 900px;

    @media only screen and (min-width : 1200px) {
      margin:auto;
    }


  }
    img{
        width: 100%;
        margin-bottom:25px;
    }

}

.content-extra-space {
    height: 800px;
}

@media only screen and (max-height : 960px) {
    .content-extra-space {
        height: 450px;
        //display: none;
    }
}

@media only screen and (max-height : 600px) {
    .content-extra-space {
        height: 350px;
    }
}
/* < for demo only */



//
//#fullpage {
//    .section {
//        h1 {
//            text-align: center;
//            color: white;
//            font-size: 3.5em;
//            text-transform: uppercase;
//        }
//
//        h2 {
//            text-align: center;
//            color: white;
//            font-size: 2.5em;
//        }
//    }
//}

