body {
    overflow: hidden!important;
}

/* Begin header */

.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 65px 30px 0 90px;
    z-index: 95;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.4s, left 0.4s linear;
    #header-logo-svg{
        width:85px;
    }
    &.header-section-white, &.header-gray {
        .logo-text {
            fill: #55565A;
        }
        .header-logo-ico {
            opacity: 1;
        }
        .main-mmu-btn {
            color: #42494a;
            fill: #42494a;
            stroke: #42494a;
            &:hover {
                color: #f5a623;
                fill: #f5a623;
                stroke: #f5a623;
            }
        }
    }
    &.header-section-logo-white {
        .logo-text {
            fill: #fff;
        }
        .header-logo-ico {
            opacity: 0.505;
        }
    }
    &.header-section-logo-gray {
        .logo-text {
            fill: #55565A;
        }
        .header-logo-ico {
            opacity: 1;
        }
    }
    &.header-section-mnu-white {
        .main-mmu-btn {
            color: #ffffff;
            fill: #ffffff;
            stroke: #ffffff;
        }
    }
    &.header-section-hide {
        opacity: 0;
    }
    &.header-section-hide-logo{
        .header-logo{
            opacity: 0;
        }
    }
}

.header-logo {
    display: block;
    width: 75px;
    height: 45px;
}
.logo-text {
    fill: #fff;
    transition: fill 0.7s;
}
.header-logo-ico {
    fill: #5285b6;
    opacity: 0.505;
    transition: opacity 0.7s;
}

.main-mmu-btn {
    display: block;
    line-height: 2.83;
    letter-spacing: 1.6px;
    color: #ffffff;
    fill: #ffffff;
    stroke: #ffffff;
    padding: 0 10px;
    font-size: 12px;
    opacity: 1;
    text-transform: uppercase;
    font-weight: normal;
    transition: all 0.7s, opacity 0.4s;
    &:hover {
        color: #f5a623;
        fill: #f5a623;
        stroke: #f5a623;
    }
    svg {
        width: 11px;
        height: 7px;
    }
    &.hide {
        opacity: 0;
    }
}

/* End header */

/* Begin main nav */

.section-nav {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 205px;
    z-index: 90;
    display: flex;
    align-items: center;
    transition: all 0.4s, right 0.4s linear;
    &.section-nav-section-white {
        .section-nav-list li {
            &.active {
                a {
                    &::before {
                        background-color: #fff;
                        border-color: #55565a;
                    }
                }
            }
            &:hover {
                a {
                    &::before {
                        background-color: #fff;
                        border-color: #fff;
                    }
                    &::after {
                        background-color: rgba(#31393e, 0.25);
                    }
                }
            }
            a {
                color: #ffffff;
                &::before {
                    background-color: #55565a;
                    border-color: #55565a;
                }
            }
        }
        .section-nav-center {
            &::after, &::before {
                background-color: rgba(#494a4d, 0.28);
            }
        }
    }
    &.section-nav-hide {
        opacity: 0;
        z-index: -1;
    }
}

.section-nav-center {
    position: relative;
    padding: 18px 0;
    width: 100%;
    &::before {
        content: "";
        position: absolute;
        left: 36px;
        top: -10000px;
        width: 1px;
        height: 10000px;
        background-color: rgba(#fff, 0.28);
        z-index: 10;
        transition: background-color 0.3s;
    }
    &::after {
        content: "";
        position: absolute;
        left: 36px;
        top: 100%;
        width: 1px;
        height: 10000px;
        background-color: rgba(#fff, 0.28);
        z-index: 10;
        transition: background-color 0.3s;
    }
}

.section-nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    li {
        position: relative;
        a {
            position: relative;
            display: block;
            padding-left: 65px;
            padding-right: 10px;
            height: 45px;
            width: 100%;
            line-height: 45px;
            color: #5285b6;
            font-size: 12px;
            letter-spacing: 1.6px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            outline: none;
            transition: all 0.25s;
            &::before {
                content: "";
                position: absolute;
                left: 33px;
                top: 50%;
                width: 9px;
                height: 9px;
                margin-top: -4px;
                border-radius: 50%;
                background-color: #52565A;
                border: 1px solid #76797C;
                z-index: 1;
                transition: all 0.3s;
            }
            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 0;
                background-color: rgba(#1C2125, 0.7);
                transition: width 0.4s;
            }
            span {
                position: relative;
                top: 0;
                left: -20px;
                opacity: 0;
                z-index: 2;
                transition: all 0.5s 0.4s, opacity 0.3s 0.4s, left 0.3s 0.4s;
            }
        }
        &.active {
            a {
                &::before {
                    background-color: #fff;
                    border: 1px solid #fff;
                }
            }
        }
        &:hover {
            span {
                opacity: 1;
                left: 0;
            }
            a {
                &::after {
                    width: 100%;
                }
            }
        }
        &:not(:hover) {
            span {
                transition: all 0s 0s;
            }
            a {
                &::after {
                    transition: all 0s 0s;
                }
            }
        }
    }
}

.btn-section-down {
    position: relative;
    display: block;
    width: 25px;
    height: 25px;
    border: 1px solid #979796;
    fill: #979796;
    border-radius: 50%;
    margin-top: 45px;
    padding: 0;
    margin-left: 23px;
    transition: all 0.25s;
    outline: none;
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.07);
        opacity: 0;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        animation-duration: 2s;
        animation-name: btnSectionDownDelayedAnim;
        animation-iteration-count: infinite;
    }
    &:hover {
        fill: #fff;
        border-color: #fff;
        background-color: #AF7F30;
        outline: none;
        &::after {
            animation-duration: 1s;
            animation-name: btnSectionDownAnim;
            animation-iteration-count: 1;
        }
    }
    > svg {
        position: relative;
        z-index: 2;
    }
    &.none {
        display: none;
    }
    &:focus {
        outline: none;
    }
}

.slider-nav {
    position: absolute;
    right: 117px;
    top: 200px;
    visibility: hidden;
    opacity: 0;
    display: flex;
    z-index: 990;
    transition: all 0.25s;
    &.open {
        visibility: visible;
        opacity: 1;
    }
}
.slider-nav-item {
    position: relative;
    margin-right: 14px;
    &:last-child {
        margin-right: 0;
    }
}

.slider-item-info {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: sofia-pro, sans-serif;
    font-style: italic;
    width: 25px;
    height: 25px;
    border: 1px solid #979796;
    color: #4a90e2;
    background-color: #2B3136;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.25s;
    &:hover {
        border-color: #fff;
    }

}

.slider-btn {
    position: relative;
    display: block;
    width: 25px;
    height: 25px;
    border: 1px solid #979796;
    fill: #979796;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.25s;
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.07);
        opacity: 0;
        transform: translate(-50%, -50%);
        border-radius: 50%;
    }
    &.slider-btn-right {
        svg {
            transform: rotate(180deg)
        }
    }
    &:hover {
        fill: #fff;
        border-color: #fff;
        background-color: #AF7F30;
    }
    > svg {
        position: relative;
        z-index: 2;
    }
}

.slider-item-info-container {
    position: absolute;
    right: 100%;
    top: 0;
    width: 700px;
    padding: 55px 47px;
    background-color: rgba(28, 32, 36, 0.76);
    border: 1px solid #5E6062;
    z-index: 111;
    color: #fff;
    font-size: 20px;
    opacity: 0;
    margin-right: 100px;
    visibility: hidden;
    font-family: sofia-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
    //transition: margin-right 0.3s;
    transition: margin-right 0.4s ease-out;
    &.open {
        margin-right: 54px;
        opacity: 1;
        visibility: visible;
        &::after {
            width: 55px;
        }
    }
    //&::after {
    //    content: "";
    //    position: absolute;
    //    left: 100%;
    //    top: 50%;
    //    width: 0;
    //    height: 1px;
    //    background: #5E6062;
    //    //transition: width 0.3s 0.3s;
    //    transition: width 0.5s ease-out 0.3s;
    //}
}
.slider-item-info-container-close {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 25px;
    height: 25px;
    //background: rgba(#F5A623, 0.327);
    border-radius: 50%;
    //border: 1px solid rgba(#fff, 0.363);
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
        background: rgba(#F5A623, 0.6);
        border-color: rgba(#fff, 0.6);
    }
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 15px;
        height: 1px;
        background-color: rgba(#fff, 0.6);
        transform: translate(-50%, -50%) rotate(45deg)
    }
    &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 15px;
        height: 1px;
        background-color: rgba(#fff, 0.6);
        transform: translate(-50%, -50%) rotate(-45deg)
    }
}

.slider-item-info-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .slider-item-info-title {
        color: #f5a623;
        line-height: 0.94;
        font-size: 30px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}
.slider-item-info-logo {
    display: block;
    max-width: 80px;
    max-height: 70px;
    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }
}
.slider-item-info-desc {
    line-height: 1.42;
    font-family: sofia-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
}


@keyframes btnSectionDownAnim {
    60% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        width: 120px;
        height: 120px;
    }
}

@keyframes btnSectionDownDelayedAnim {
    0% {
        opacity: 0;
        width: 100%;
        height: 100%;
    }
    59% {
        opacity: 0;
        width: 100%;
        height: 100%;
    }
    60% {
        opacity: 1;
        width: 100%;
        height: 100%;
    }
    100% {
        opacity: 0;
        width: 120px;
        height: 120px;
    }
}

/* End main nav */

/* Begin main nav */

#home {
    position: relative;
    left: 0;
    top: 0;
    transition: left 0.4s linear;
    &.main-mmu-open {
        left: -360px;
        .section-nav {
            right: 360px;
        }
        .header {
            left: -360px;
        }
    }
}

.main-nav {
    position: fixed;
    right: -360px;
    top: 0;
    width: 360px;
    height: 100vh;
    z-index: 1000;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #31383E;
    padding: 124px 0 26px 33px;
    color: #ffffff;
    transition: right 0.4s linear;
    &.open {
        right: 0;
    }
    .main-nav-footer {
        background: transparent;
        border: none;
        font-size: 12px;
        line-height: 1.42;
        letter-spacing: 1.6px;
        color: rgba(#ffffff, 0.33);
        margin-top: 17px;
        padding-top: 15px;
        border-top: 1px solid rgba(#fff, 0.12);
        p {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        a {
            color: rgba(#ffffff, 0.33);
            &:hover {
                color: rgba(#ffffff, 0.33);
                text-decoration: underline;
            }
        }
    }
}
.main-nav-header {
    padding-bottom: 28px;
    border-bottom: 1px solid rgba(#fff, 0.26);
    margin-bottom: 17px;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        margin-left: -10px;
        li {
            margin-left: 10px;
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15px;
                height: 15px;
                color: #fff;
            }
            &:hover {
                .nav-ico-search,
                .nav-ico-lock {
                    fill: #f5a623;
                }
                .nav-ico-menu {
                    stroke: #f5a623;
                }
            }
        }
    }
}
.nav-ico-search,
.nav-ico-lock {
    fill: #fff;
    transition: all 0.25s;
}
.nav-ico-menu {
    stroke: #fff;
    transition: all 0.25s;
}
.main-nav-mnu-title {
    line-height: 2.83;
    letter-spacing: 1.6px;
    color: #C2C4C5;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: normal;
}
.main-nav-mnu-list {
    font-size: 12px;
    text-transform: uppercase;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        a {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            color: #C2C4C5;
            font-size: 12px;
            font-weight: normal;
            line-height: 2.83;
            outline: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            letter-spacing: 1.6px;
            border-left: 4px solid transparent;
            transition: all 0.25s;
            &:hover {
                background-color: #23292E;
                border-left-color: #f5a623;
            }
        }
    }
    > li {
        > a {
            padding-left: 19px;
        }
        > ul {
            list-style: none;
            margin: 0;
            padding: 0;
            > li {
                > a {
                    padding-left: 44px;
                    display: flex;
                    align-items: center;
                    &::before {
                        content: "";
                        position: relative;
                        left: 0;
                        top: 0;
                        display: block;
                        width: 4px;
                        height: 4px;
                        margin-right: 5px;
                        border-radius: 50%;
                        background-color: #C2C4C5;
                        opacity: 0;
                        transition: all 0.25s;
                    }
                    &:hover {
                        padding-left: 53px;
                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }
        }
        &.main-nav-mnu-item-has-children {
            > a {
                padding-left: 29px;
                &::after {
                    content: "";
                    position: absolute;
                    left: 19px;
                    top: 50%;
                    width: 5px;
                    height: 1px;
                    margin-top: -1px;
                    background-color: #C2C4C5;
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: 19px;
                    top: 50%;
                    width: 5px;
                    height: 1px;
                    margin-top: -1px;
                    background-color: #C2C4C5;
                    transform: rotate(-90deg);
                    transition: transform 0.3s;
                }
            }
            > ul {
                display: none;
            }
        }
        &.open {
            > a {
                &::before {
                    transform: rotate(0)
                }
            }
        }
    }
}

.main-nav-close {
    position: absolute;
    right: 30px;
    top: 70px;
    cursor: pointer;
    z-index: 2;
    font-size: 12px;
    padding: 0 10px;
    color: #C2C4C5;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    &:hover {
        color: #F5A623;
    }
    span {
        display: block;
        padding: 0 0 0 5px;
        line-height: 2.83;
        letter-spacing: 1.6px;
        text-align: center;
    }
    .nav-ico-close {
        position: relative;
        left: 0;
        top: -1px;
    }
}
.nav-ico-close {
    width: 8px;
    height: 6px;
    stroke: #F5A623;
}

/* End main nav */

/* Begin main block */

.section-main {
    position: relative;
}

.section-main-container {
    padding: 0 240px 0 90px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.section-main-date {
    font-size: 13px;
    line-height: 2.62;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 20px;
}

.section-main-link-article {
    display: inline-block;
    line-height: 1.42;
    letter-spacing: normal;
    color: #ffffff;
    font-size: 24px;
    margin-bottom: 15px;
    margin-top: 5px;
    font-family: sofia-pro, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    &:hover {
        color: #f5a623;
    }
}

.section-main-container-title {
    max-width: 630px;
    .section-main-slider-title {
        margin-bottom: 20px;
    }
}
.section-main-container-end {
    position: relative;
    width: 100%;
    max-width: 900px;
    z-index: 2;
}

.section-main-container-footer {
    position: relative;
    display: block;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.62;
    letter-spacing: normal;
    color: rgba(#ffffff, 0.7);
    text-align: right;
    overflow: hidden;
    z-index: 105;
    .section-main-container-footer-content {
        display: inline-block;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: -1000px;
            top: 50%;
            width: 1000px;
            margin-left: -20px;
            height: 1px;
            background-color: rgba(#ffffff, 0.26);
        }
    }
    &:hover {
        color: #ffffff;
    }
}

#footer-logo-svg{
    width:105px;
    .logo-text {
        fill: #ffffff;
    }
}

.section-latest-btn {
    position: absolute;
    bottom: -100%;
    right: 210px;
    display: flex;
    width: 95px;
    height: 45px;
    z-index: 2;
    transition: bottom 0.4s;
    &:hover {
        svg {
            opacity: 0.65;
        }
    }
    &.open {
        bottom: -1px;
    }
    span {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        font-size: 12px;
        color: #fff;
        line-height: 1;
        margin-top: 2px;
        letter-spacing: 1.629px;
    }
    img, svg {
        display: block;
        margin: 0 auto;
    }
    svg {
        transition: all 0.25s;
        opacity: 0.494;
        fill: #F5A623;
        stroke: #fff;
    }
}

/* End main block */

/* Begin section slider */

.section-slider-item {
    position: relative;
    padding-bottom: 118px;
    padding-top: 170px;
}

.section-slider-item-info {
    display: none;
}

.section-slider-item-bg {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-blend-mode: multiply;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    transform: scale(1.05);
    transition: transform 0s;
    &.scale {
        transform: scale(1);
        transition: transform 2.5s;
    }
}

.wrapp-section-slider {
    min-height: 100vh;
}
.section-slider,
.section-slider-item {
    position: relative;
    height: 100vh;
    min-height: 100vh;
    width: 100vw;
    z-index: -1;
}

/* End section slider */

.ui-loader {
    display: none;
}
.wow-slide,
.wow,
.anim {
    visibility: hidden;
}

/* Begin pagenation */

.pagination-list {
    display: flex;
    justify-content: center;
    font-size: 17px;
    line-height: 1.57;
    list-style: none;
    font-family: sofia-pro, sans-serif;
    margin: 0;
    padding: 0;
    li {
        position: relative;
        border-bottom: 2px solid #DDDDDD;
        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 5px;
            background-color: transparent;
        }
        a {
            display: block;
            color: #f5a623;
            width: 75px;
            padding: 14px 0;
            text-align: center;
        }
        &.active {
            a {
                color: #494a4d;
            }
            &::after {
                background-color: #CACACA;
            }
        }
        &:hover {
            a {
                color: #494a4d;
            }
        }
    }
}

/* End pagination */

/* Begin partners */

.partners {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.partners_item {
    width: 120px;
    height: 65px;
    display: flex;
    padding: 5px;
    padding-right: 10px;
    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
}

/* End partners */


/* Begin main content */

.main-content {
    position: relative;
    padding: 150px 0 78px;
}

.container-text {
    padding: 0 515px 0 225px;
}

.content-page-name {
    font-size: 13px;
    color: #353637;
    line-height: 1.4;
    padding: 8px 0;
    margin-bottom: 25px;
    font-weight: normal;
    //line-height: 2.62;
}

.content {
    position: relative;
    width: 100%;
    color: #494a4d;
}

.content-print {
    display: inline-block;
    margin-top: 30px;
    fill: #42494A;
    text-transform: uppercase;
    color: #42494a;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 1.6px;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.25s;
    &:hover {
        fill: #000;
        color: #000;
    }
}
.print-ico {
    display: inline-block;
    vertical-align: middle;
}
.content-print-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}

/* End main content */

/* Begin blog */

.hr-blog {
    width: 100%;
    height: 2px;
    background-color: #353637;
    opacity: 0.26;
    margin-bottom: 49px;
}

.blog-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 832px;
}
.blog-col {
    width: 50%;
    padding: 0 43px;
}

.blog-item {
    position: relative;
    margin-bottom: 90px;
    text-align: center;
}
.blog-item-img {
    position: relative;
    display: block;
    width: 100%;
    height: 220px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #e9e9e9;
    margin-bottom: 13px;
}
.blog-item-title {
    display: block;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: center;
    color: #5285b6;
    font-size: 24px;
    height: 68px;
    overflow: hidden;
    margin-bottom: 21px;
    font-family: sofia-pro, sans-serif;
    &:hover {
        text-decoration: none;
        color: #F5A623;
    }
}
.blog-item-desc {
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #494a4d;
    margin-bottom: 40px;
}
.blog-item-footer {
    border-top: 2px solid rgba(#353637, 0.26);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.blog-item-date {
    font-size: 13px;
    line-height: 2.62;
    letter-spacing: normal;
    color: #4a4a4a;
}
.blog-item-catg {
    font-size: 13px;
    a {
        font-size: 13px;
        color: #5285b6;
        &:hover {
            color: #F5A623;
        }
    }
}


/* End blog */

/* Begin section slider */

.branding-slide-info {
    position: absolute;
    left: 0;
    //bottom: 0;

    width: 100%;
    max-width: 750px;
    padding-right: 75px;
    padding-bottom: 37px;
    padding-top: 41px;
    background-color: rgba(#000, 0.9);
    padding-left: 60px;
    height: auto;


   // height: 46px;
    transition: all 1s ease-out;

    background-color:transparent;
    border-top-width:1px;
    //border-top-color :rgba(255,255,255,0.28);
    border-top-color : rgba(151,151,150,0.654);
    border-top-style: solid;
    bottom: 0px;
    //height:40px;

    .branding-slide-info-title {
        margin: 0;
        font-size: 24px;
    }
}

.slider-item-close, .slider-item-open {
    position: absolute;
    right: 18px;
    top: 18px;
    width: 25px;
    height: 25px;
    //background: rgba(#F5A623, 0.327);
    border-radius: 50%;
    //border: 1px solid rgba(#fff, 0.363);
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
        background: rgba(#F5A623, 0.6);
        border-color: rgba(#fff, 0.6);
    }
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width:12px;
        height: 1px;
        background-color: rgba(#fff, 0.6);
        transform: translate(-50%, -50%) rotate(45deg)
    }
    &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 12px;
        height: 1px;
        background-color: rgba(#fff, 0.6);
        transform: translate(-50%, -50%) rotate(-45deg)
    }
}

.slider-item-close{
    display: none;
}

.slider-item-open {
    top: 4px;

    svg{
        //fill: white;
        fill: rgba(151,151,150,0.654);;
        -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
        -moz-transform: rotate(180deg);        /* FF */
        -o-transform: rotate(180deg);          /* Opera */
        -ms-transform: rotate(180deg);         /* IE9 */
        transform: rotate(180deg);             /* W3C compliant browsers */
    }

    &:hover {
        fill: white;
    }
    &::after {
        content: none;
    }
    &::before {
        content: none;
    }
}

.section-slider-wide {
    .slide {
        position: relative;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}

.fp-controlArrow.fp-next,
.fp-controlArrow.fp-prev {
    position: absolute;
    top: 50%;
    margin-top: -12px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    fill: #979796;
    margin: 0;
    outline: none;
    border: 1px solid rgba(#979796, 0.654);
    transition: all 0.25s;
    z-index: 111111;
    &:hover {
        fill: rgb(255, 255, 255);
        background-color: rgb(175, 127, 48);
        border-color: rgb(255, 255, 255);
    }
    svg {
        width: 100%;
        height: 100%;
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
}
.fp-controlArrow.fp-next {
    transform: rotate(180deg);
    //right: 40px;
    right: 245px;
}
.fp-controlArrow.fp-prev {
    left: 90px;
}


@media only screen and (max-width: 1023px) {
    .fp-controlArrow.fp-next {
        right: 135px;
    }
}
/* End section slider */

/* Begin section carusel min */
.carusel-section-min,
.carusel-section-min-item {
    display: block;
    width: 100%;
    height: 450px;
}
.carusel-section-min-item {
    padding: 2px 3px 5px 0;
}
.carusel-section-min-item-inner {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5)
}

.owl-theme {
    &.carusel-section-min {
        .owl-nav {
            margin-top: 0;
            .owl-prev,
            .owl-next {
                position: absolute;
                margin-top: -12px;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                fill: #979796;
                margin: 0;
                outline: none;
                border: 1px solid rgba(#979796, 0.654);
                transition: all 0.25s;
                &:hover {
                    fill: rgb(255, 255, 255);
                    background-color: rgb(175, 127, 48);
                    border-color: rgb(255, 255, 255);
                }
                svg {
                    width: 100%;
                    height: 100%;
                }
            }
            .owl-prev {
                left: 16px;
                top: 16px;
            }
            .owl-next {
                right: 16px;
                top: 16px;
                transform: rotate(180deg);
            }
        }
    }
}

.carusel-section-min-item-img-zoom {
    position: absolute;
    right: 11px;
    bottom: 13px;
    width: 16px;
    height: 16px;
    fill: #353637;
    outline: none;
    transition: all 0.25s;
    svg {
        width: 100%;
        height: 100%;
    }
    &:hover {
        transform: scale(1.2);
    }
}

.wrapp-carusel-section-min {
    position: relative;
}

.carusel-section-min-item-info {
    position: absolute;
    left: 72px;
    top: 100%;
    width: 100%;
    padding: 70px 72px 70px 30px;
    text-align: right;
    font-size: 16px;
    color: #353637;
    line-height: 1.25;
    margin-top: -50px;
    background-color: #e9e9e9;
    font-family: sofia-pro, sans-serif;
    font-style: italic;
}

.col-l-right {
    .content-linls {
        margin-left: 45px;
    }
}

/* End section carusel min */

/* Begin content links */

.content-linls {
    position: relative;
    z-index: 2;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -25px;
    margin-top: 40px;
    li {
        display: block;
        margin-left: 25px;
        margin-bottom: 15px;
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            fill: #fff;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #5285b6;
            &:hover {
                background-color: #5285b6*1.2;
            }
        }
    }
}

/* End content links */

.is-animated {
    opacity: 0;
}

.animated {
    animation-duration: 1.5s;
    animation-fill-mode: both;
    opacity: 1;
}


.animated-2s {
    animation-duration: 1s;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    opacity: 1;
}

@media only screen and (max-width: 1023px) {
  .section-swiping-text-right {
    padding-right: 60px;
  }
}

@media only screen and (max-width: 960px) and (min-width: 768px) {
    .branding-slide-info{
        max-width: calc(100% - 123px);
    }
}
@media only screen and (max-width: 1023px) and (min-width: 800px) {
    .section-center-text-testimonial {
        margin: 0px 20px;
    }


}

.section-contact-form{
    color:#fff;
}
.blockType-10 .input-submit-contact-form{
    float:none;
}

