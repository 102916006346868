@media only screen and (max-width: 1400px) {
    .container-text {
        padding: 0 225px;
    }
}

@media only screen and (max-width: 1360px) {

    // .col-l-left {
    //     width: 45%;
    //     min-width: 45%;
    // }
    .col-l-right {
        width: 55%;
    }
    .carusel-section-min, .carusel-section-min-item {
        height: 350px;
    }
    .carusel-section-min-item-info {
        left: 30px;
        padding-right: 33px;
    }
    .col-l-right .content-linls {
        margin-left: -25px;
    }

}


@media only screen and (max-width: 1023px) {

  .col-l-right .content-linls {
    margin-left: -36px;
  }
}
 
@media only screen and (max-width: 1200px){
    .col-left {
        min-width: 350px;
        max-width: 350px;
        margin-right: 50px;
    }
    .col-content,
    .col {
        padding-right: 230px;
    }
    .col-partners {
        margin-right: 0;
        padding-right: 230px;
        max-width: 100%;
    }
    .container {
        overflow: initial;
    }
    .blog-col {
        width: 50%;
    }

    .col-l-right {
        padding-right: 195px;
    }
}

@media only screen and (max-width: 1025px) {

    .section-blog {
        .section-padding-container {
            padding-top: 125px;
            padding-bottom: 40px;
        }
    }
    .blog-row {
        max-width: 572px;
        margin-bottom: -51px;
    }
    .blog-item {
        margin-bottom: 51px;
    }
    .blog-col {
        padding: 0 23px;
    }
    .blog-item-img {
        height: 152px;
    }
    .blog-item-title {
        font-size: 20px;
        height: 56px;
        margin-bottom: 10px;
    }
    .blog-item-desc {
        font-size: 15px;
        margin-bottom: 21px;
    }

    .pagination {
        display: none;
    }
    
    .section-blog {
        .container-center {
            display: flex;
            align-items: center;
        }
    }

    .section-img-title-wide{
        padding: 70px 100px 70px 50px;
    }

}

@media only screen and (max-width: 1023px) {

    .row {
        flex-wrap: wrap;
    }
    .col-left {
        &.col-padding {
            padding-bottom: 55px;
        }
    }
    .col-right {
        &.col-padding {
            padding-top: 0;
        }
    }
    .col-left,
    .container-c .col-left {
        min-width: 100%;
        max-width: 100%;
        padding-right: 152px;
    }
    .col-right {
        width: 100%;
    }
    .col-right-tablet-end {
        display: flex;
        justify-content: flex-end;
        .section-img {
            width: auto;
        }
    }

    .container {
        margin-left: 0;
        padding-left: 139px;
    }

    .container-text {
        padding: 0 139px;
    }

    .header {
        padding: 50px 40px 0 44px;
    }

    .section-nav {
        width: 127px;
    }

    .section-latest-btn {
        right: 145px;
    }

    .section-slider-item {
        padding-bottom: 100px;
        padding-top: 130px;
    }

    .section-main-container {
        padding-left: 44px;
        padding-right: 160px;
    }

    .main-mmu-btn-text {
        display: none;
    }
    .main-mmu-btn {
        line-height: 1;
        padding: 0;
        svg {
            width: 28px;
            height: 20px;
        }
    }
    .btn-section-down {
        padding: 0;
    }
    .section-nav-list li a span {
        display: none;
    }

    .section-main-container-footer {
        line-height: 1;
        margin-top: 30px;
        .section-main-container-footer-content {
            padding-top: 10px;
            &::before {
                margin: 0;
                left: initial;
                right: 0;
                top: 0;
                height: 2px;
            }
        }
    }
    
    .btn-section-down {
        margin-top: 30px;
    }
    .section-nav-list li a {
        height: 35px;
        line-height: 35;
        &::before {
            width: 6px;
            height: 6px;
        }
    }

    .section-main-container-title {
        .title-orange {
            font-size: 30px;
        }
        .section-main-slider-title {
            font-size: 32px;
        }

        .section-main-slider-title div p {
            display: none;
        }
    }

    .main-nav-close {
        padding: 0;
        width: 15px;
        height: 15px;
        right: 55px;
        top: 65px;
        span {
            display: none;
        }
        .nav-ico-close {
            display: none;
        }
        &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: rotate(45deg);
            width: 18px;
            height: 2px;
            margin-top: -1px;
            margin-left: -9px;
            background-color: #f5a623;
        }
        &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: rotate(-45deg);
            width: 18px;
            height: 2px;
            margin-top: -1px;
            margin-left: -9px;
            background-color: #f5a623;
        }
    }

    .main-nav {
        padding-top: 120px;
    }

    .btn-section-down {
        width: 27px;
        height: 27px;
        margin-left: 22px;
    }
    .section-nav-center {
        padding: 25px 0;
    }
    .btn-section-down {
        fill: #fff;
        border-color: #fff;
        background-color: #AF7F30;
    }

    .main-nav-header {
        .nav-ico-menu {
            width: 17px;
            height: 17px;
        }
        .nav-ico-search {
            width: 13px;
            height: 13px;
        }
        .nav-ico-lock {
            width: 17px;
            height: 17px;
        }
    }
    .main-nav-header ul {
        margin-left: -20px;
        li {
            margin-left: 20px;
        }
    }
    .main-nav-header ul li a {
        width: 20px;
        height: 20px;
    }
    
    .col-content,
    .col {
        padding-right: 175px;
    }
    .col-tablet-wide {
        padding-right: 18px;
    }
    .col-subtitle {
        margin-bottom: 70px;
    }
    .align-table-beetween {
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .section-img-tablet-maxwidth-340 {
        max-width: 340px;
    }
    .section-img-padding-bg-left {
        margin-right: -170px;
    }

    .slider-nav {
        right: 39px;
    }

    .slider-item-info-container {
        width: 520px;
        font-size: 22px;
    }

    .header {
        &.header-section-mnu-white {
            .main-mmu-btn {
                color: #42494a;
                fill: #42494a;
                stroke: #42494a;
            }
        }
    }

    .section-padding-container {
        padding-left: 40px;
        padding-right: 40px;
    }

    .branding-slide-info {
        padding-left: 44px;
    }

    .col-l-right {
        padding-right: 25px;
    }
    .col-l-left {
        padding-right: 30px;
    }
    .carusel-section-min-item-info {
        left: 17px;
        padding-right: 20px;
    }

    .container-c {
        padding-left: 44px;
    }
    .fp-controlArrow.fp-prev {
        left: 44px;
    }

    .col-padding {
        padding-bottom: 0;
    }

}


@media only screen and (max-width: 800px) {

    .blog-row {
        margin-left: -23px;
        margin-right: -23px;
    }
    .section-blog .section-padding-container {
        padding-right: 182px;
    }

}

@media only screen and (max-width: 767px) {
    body {
        font-size: 15px;
    }
    h1, h2, h3 {
        margin-bottom: 20px;
    }
    h1 {
        font-size: 32px;
        line-height: 1;
    }
    h2 {
        font-size: 28px;
        line-height: 1.04;
    }
    h3 {
        font-size: 25px;
        line-height: 1.04;
    }
    .section {
        &.section-border {
            &::before {
                border-width: 0;
            }
        }
    }

    .section-nav {
        display: none;
    }
    .section-latest-btn {
        right: 20px;
    }

    .header {
        padding: 29px 25px 0;
    }
    .section-main-container {
        padding-left: 25px;
        padding-right: 18px;
    }

    .section-main-container-footer {
        display: none;
    }
    .section-slider-item {
        padding-top: 240px;
        padding-bottom: 150px;
        padding: 0;
    }

    .main-nav {
        padding-top: 70px;
        padding-left: 25px;
    }
    .main-nav-close {
        top: 40px;
        right: 35px;
    }
    .main-nav-footer {
        display: none;
    }
    .col-right-img {
        display: none;
    }
    
    .section-scroll-next-block {
        position: absolute;
        left: 50%;
        display: flex;
        justify-content: center;
        width: 28px;
        height: 28px;
        fill: #fff;
        bottom: 5px;
        margin-left: -14px;
        z-index: 101;
    }
    .section-scroll-next-block-arrows {
        svg {
            display: block;
            margin-bottom: 2px;
        }
    }

    .section-scroll-next-block-arrows {
        svg {
            &:first-child {
                animation: animate-arrow-3 1.5s ease-in-out infinite 0.2s;
            }
            &:nth-child(2) {
                animation: animate-arrow-2 1.5s ease-in-out infinite 0.1s;
            }
            &:nth-child(3) {
                animation: animate-arrow-1 1.5s ease-in-out infinite;
            }
        }
    }

    @keyframes animate-arrow-1 {
        0%{transform:translateY(-20px);opacity: 0;}
        70%,100%{transform:translateY(0px);opacity: 1;}
    }
    @keyframes animate-arrow-2 {
        0%{transform:translateY(-10px);opacity: 0;}
        70%,100%{transform:translateY(0px);opacity: 1;}
    }
    @keyframes animate-arrow-3 {
        0%{transform:translateY(-5px);opacity: 0;}
        70%,100%{transform:translateY(0px);opacity: 1;}
    }
	
    .container {
        padding-left: 25px;
        padding-right: 0;
    }
    .col-left,
    .container-c .col-left {
        padding-right: 18px;
    }
    .col-padding {
        padding-top: 100px;
    }

    .col-left.col-padding {
        padding-bottom: 40px;
    }

    .col-content,
    .col {
        padding-right: 18px;
    }
    .col-subtitle {
        margin-bottom: 20px;
        width: 100%;
        padding-right: 18px;
        min-width: 100%;
        max-width: 100%;
    }
    .col-content {
        .title-orange {
            margin-bottom: 35px;
        }
    }
    .row-padding {
        padding-top: 100px;
    }
    

    .col-mobile-last {
        padding-bottom: 50px;
    }

    .wow,
    .wow-slide {
        animation: none!important;
        visibility: visible!important;
    }

    .slider-nav {
        display: none;
    }

    .container-text {
        padding: 0 25px;
    }
    .main-content {
        padding-top: 135px;
        padding-bottom: 50px;
    }

    .blog-col {
        width: 50%;
    }
    .section-blog .section-padding-container {
        padding-right: 25px;
    }
    .blog-row {
        margin: 0 auto;
    }

    .section-padding-container {
        padding-top: 110px;
        padding-bottom: 50px;
        padding-left: 25px;
        padding-right: 25px;
    }
    .blog-item {
        margin-bottom: 40px;
    }
    .blog-col {
        &:last-child {
            .blog-item {
                margin-bottom: 0;
            }
        }
    }

    .branding-slide-info {
        padding-left: 25px;
        padding-right: 25px;
    }
    .slider-item-close {
        right: 15px;
        right: 15px;
    }
    .branding-slide-info {
        padding-top: 55px;
        padding-bottom: 30px;
    }

    .section-center-text-bg {
        font-size: 18px;
    }
    .section-center-text-bg-t {
        line-height: 1.2;
    }

    .section-main-container {
        padding-bottom: 40px;
        &.align-items-end {
            align-items: flex-end;
        }
    }
    
    // .col-l-left {
    //     display: none;
    // }
    .col-l-right {
        width: 100%;
        padding-right: 25px;
        padding-left: 25px;
    }

    .fp-controlArrow.fp-prev {
        left: 25px;
    }
    .fp-controlArrow.fp-next {
        right: 25px;
    }

    .section-swiping-hide {
        display: none;
    }
    
    .section-swiping-container {
        display: block!important;
        overflow: hidden;
        height: 100%;
        width: 100%;
        .section-swiping-slide {
            height: 100vh;
            width: 100vw;
            padding: 132px 25px 20px 25px;
            > * {
                &:last-child {
                    margin-bottom: 15px;
                }
            }
            > p {
                margin-bottom: 15px;
            }
            .fp-scrollable,
            .fp-scroller {
                overflow: initial;
            }
        }
    }
    .section-img-padding-l img {
        margin-left: 0;
    }
    .section-img-title-wide {
        position: absolute;
        top: 100%;
        right: 0;
        margin-top: -19px;
        width: 100%;
        padding: 33px 17px 40px 30px;
        z-index: -1;
        &::before {
            content: "";
            position: absolute;
            right: 100%;
            top: 0;
            height: 100%;
            width: 16px;
            background-color: #e9e9e9;
        }
    }
    .section-img {
        position: relative;
        right: 0;
        right: -25px;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        max-height: 100%;
        max-width: 100%;
        justify-content: flex-end;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .section-swipe-ico {
        display: block;
    }

    .section-img-padding-bg-left::before {
        content: none;
    }
    .section-img-padding-bg-left {
        right: 0;
        height: auto;
        margin: 0;
        width: calc(100% + 25px);
        max-height: 100%;
        padding: 15px 25px;
        text-align: right;
    }
    .section-img- {
        position: relative;
        top: 0;
        right: -25px;
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin-left: auto;
    }
    .section-swiping-container.owl-carousel .owl-item img {
        width: auto;
        height: auto;
    }
    .content-linls {
        margin-top: 20px;
    }

    .section-img-padding-bg-bottom::after {
        content: none;
    }

    .partners_item {
        width: 90px;
        height: 50px;
        &:nth-child(n+24) {
            display: none;
        }
    }

}


@media only screen and (max-width: 620px) {

    .blog-row {
        max-width: 340px;
    }
    .blog-item-img {
        height: 182px;
    }
    .blog-col {
        width: 100%;
        padding: 0;
    }
    .blog-item-title {
        height: auto;
    }
    .section-main-link-article {
        font-size: 20px;
    }

}


@media only screen and (max-width: 568px) {
    .section-main-container-title .section-main-slider-title {
        font-size: 27px;
    }

    .content-linls {
        margin-left: -10px;
        margin-top: 20px;
        li {
            margin-left: 10px;
            a {
                width: 45px;
                height: 45px;
            }
        }
    }
    .col-l-right .content-linls {
        margin-left: -11px;
    }
}


@media only screen and (max-width: 480px) {
    .main-nav {
        width: 100%;
        right: -100vw;
    }
    #home.main-mmu-open { 
        left: -100vw;
        .header {
            left: -100vw;
        }
    }
    .footer-bottom {
        padding-left: 0;
        padding-right: 0;
    }
    
}

@media only screen and (min-width: 1350px) {

    .blog-row {
        max-width: 1250px;
    }
    .blog-col {
        width: 33.3%;
    }
 
}
@media only screen and (max-height: 800px) and (min-width: 768px) {
    .col-padding {
        &.partners-padding {
            padding-bottom: 30px;
        }
    }
}

@media only screen and (max-height: 900px) {
    .slider-nav {
        top: 130px;
    }
    .slider-item-info-container {
        top: 0!important;
        //&::after {
        //    top: 12px;
        //}
    }
}

@media only screen and (max-height: 900px) {

    .slider-nav {
        top: 110px;
    }

}

@media only screen and (max-height: 770px) {
    .col-image-hg {
        padding-top: 100px;
    }
    .slider-nav {
        top: 35px;
    }
}


@media only screen and (max-height: 650px) {
    .section {
        //height: auto!important;
        //min-height: 100vh;
        &.section-border {
            &::before {
                border-width: 0;
            }
        }
    }
    .col-image-hg {
        padding-top: 120px;
    }
}

@media only screen and (max-height: 650px) and (min-width: 767px)  {

    /*.section {
        height: auto!important;
        min-height: 100vh;
    }*/
    .wow,
    .wow-slide {
        animation: none!important;
        visibility: visible!important;
    }

}
@media only screen and (max-height: 550px) and (min-width: 0px)  {

    /*.section {
        height: auto!important;
        min-height: 100vh;
    }*/
    .wow,
    .wow-slide {
        animation: none!important;
        visibility: visible!important;
    }

    .col-image-hg {
        padding-top: 90px;
    }
    .section-text-italics {
        margin-bottom: 15px;
    }
    .section-sub-title {
        margin-bottom: 10px;
    }

}

@media only screen and (max-height: 700px) and (max-width: 1025px) {
 
    .slider-nav {
        top: 20px;
    }

}

@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) {

    .blog-item-desc {
        display: none;
    }

}

@media only screen and (min-width : 567px) and (max-width : 900px) and (orientation : landscape) {

    .section-slider-item {
        padding-bottom: 20px;
    }
    .section-main-container {
        padding-bottom: 0;
    }
    .section-main-container-title .section-main-slider-title {
        font-size: 20px;
    }
    .section-main-link-article {
        font-size: 18px;
        margin-top: 0;
    }
    .section-main-container-title .title-orange {
        font-size: 24px;
    }
    .section-main-date {
        margin-bottom: 10px;
    }
    .section-blog .section-padding-container {
        padding-top: 95px;
    }
    .blog-item-img {
        height: 182px;
        margin-bottom: 0;
        width: 288px;
        min-width: 288px;
        margin-right: 16px;
    }
    .blog-item-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between
    }
    .blog-item-title {
        font-size: 24px;
        height: auto;
        margin-bottom: 15px;
        margin-top: 12px;
    }

    .blog-item-desc {
        display: none;
    }
    .blog-col {
        width: 100%;
        padding: 0;
    }
    .blog-row {
        margin-right: auto;
        margin-left: auto;
        max-width: 100%;
    }
    .blog-item {
        display: flex;
    }

    .section-swiping-hide {
        display: none;
    }

    .section-swiping-container {
        display: block!important;
        overflow: hidden;
        height: 100%;
        width: 100%;
        .section-swiping-slide {
            height: 100vh;
            width: 100vw;
            padding: 90px 25px 30px 25px;
            > * {
                &:last-child {
                    margin-bottom: 15px;
                }
            }
            > p {
                margin-bottom: 15px;
            }
        }
    }
    .section-img-padding-l img {
        margin-left: 0;
    }

    .section-img {
        position: relative;
        right: 0;
        right: -25px;
        margin-left: auto;
        width: 100%;
        height: auto;
        max-height: 100%;
        max-width: 100%;
        img {
            max-height: 100%;
            max-width: 100%;
            margin-left: auto;
        }
    }

    .section-nav {
        display: none;
    }

    .header {
        padding: 29px 25px 0;
    }

    .section-swipe-ico {
        display: block;
        right: 75px;
        top: 35px;
    }

    .section-img-title-wide {
        position: absolute;
        top: initial;
        bottom: 0;
        right: 0;
        margin-top: 0;
        width: 100%;
        padding: 16px 17px 15px 30px;
        z-index: 3;
        &::before {
            content: "";
            position: absolute;
            right: 100%;
            top: 0;
            height: 100%;
            width: 16px;
            background-color: #e9e9e9;
        }
    }

    .section-img-padding-bg-left::before {
        content: none;
    }
    .section-img-padding-bg-left {
        right: 0;
        height: auto;
        margin: 0;
        width: calc(100% + 25px);
        padding: 15px 25px;
        text-align: right;
        max-height: auto;
    }
    .section-img {
        .section-img-padding-bg-left {
            margin-bottom: 0;
        }
    }
    .section-img- {
        position: relative;
        top: 0;
        right: -25px;
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin-left: auto;
    }
    .section-swiping-container.owl-carousel .owl-item img {
        width: auto;
        height: auto;
    }
    .content-linls {
        margin-top: 20px;
    }

    .section-img-padding-bg-bottom::after {
        content: none;
    }
    
    .partners_item {
        width: 90px;
        height: 50px;
        &:nth-child(n+20) {
            display: none;
        }
    }

    .row-padding {
        padding-top: 90px;
    }

    .branding-slide-info {
        padding-top: 40px;
    }

}

@media only screen and (max-height:600px) {

    .section-swiping-container.owl-carousel .owl-item img {
        width: 100%;
        height: 100%;
    }
}


.main-mmu-btn-text {
    margin-left: 8px;
}
