/*!
*  Stunnplate nav.css
*  Version: 1
*/
/*
*  Last Updated: 11/11/15
*  By: Adam
*  
*  Notes:
*  Flex nav layout
*
*  Dependencies:
*  core/default.1.1.css; - Requires Show/Hide Media Queries
* 
*/

/*-------------------------------------------------------*/
/*- MAIN MENU                                           -*/
/*-------------------------------------------------------*/

.flex-nav {
	/*height               :100%;*/
	display                : -ms-flexbox;
	display                : -webkit-flex;
	display                :         flex;

	padding                : 0 16px;

	-webkit-align-items    : center;
	-ms-flex-align         : center;
	align-items            : center;
	-webkit-flex-grow      : 1;
	-ms-flex-positive      : 1;
	flex-grow              : 1;
	-webkit-justify-content: space-between;
	-ms-flex-pack          : justify;
	justify-content        : space-between;
}



.flex-nav .logo {
	line-height: 0;

	/*margin-right: auto;*/
	padding: 0 0 0 16px;
}
.flex-nav .logo a {
	display: inline-block;

	padding: 0;
}

.flex-ul {
	display                : -ms-flexbox;
	display                : -webkit-flex;
	display                :         flex;
	
	-webkit-justify-content: flex-end;
	-ms-flex-pack          : end;
	justify-content        : flex-end;
}

.ie9 .flex-nav {text-align:right; display:table;}
.ie9 .flex-nav .logo {display: table-cell; vertical-align: middle; margin-right: auto; text-align:left;}
.ie9 .flex-nav .logo a {}
.ie9 .flex-ul {
	display:table-cell;
	text-align: right;
	vertical-align: middle;
}
.ie9 .flex-ul li {
	display:inline-block;
}

#menu-button {
	-webkit-flex: 0 0 auto;
	-ms-flex    : 0 0 auto;
	flex        : 0 0 auto;
	-webkit-align-self :center;
	-ms-flex-item-align:center;
	align-self         :center;
	width:48px;
	height:48px;
	position:relative;
}

#menu-button span {
	font-size: 0;
	line-height: 0;

	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: block;

	padding: 0;

	color: white;
}
#menu-button span span {
	position: absolute;
	top: 50%;

	display: block;
	overflow: visible;

	width: 30px;
	height: 4px;
	margin: -2px auto 0 auto;

	-webkit-transition: opacity .25s ease-in-out;
	   -moz-transition: opacity .25s ease-in-out;
	        transition: opacity .25s ease-in-out;

	background: white;
}


#menu-button > span:before,
#menu-button > span:after {
	position: absolute;
	left: 50%;

	width: 30px;
	height: 4px;
	margin-left: -15px;

	content: '';
	transition-timing-function: cubic-bezier(.445, .05, .55, .95);
	transition-duration: 200ms;
	-webkit-transform: translate3d(0px, 0px, 0px);
	        transform: translate3d(0px, 0px, 0px);

	background: white;
}


#menu-button > span:before {
	top: 12px;
}
#menu-button > span:after {
	bottom: 12px;
}

#menu-button.close span span {
	opacity: 0;
}
#menu-button.close > span:before {
	top: 22px;

	-webkit-transform: rotate(45deg) translateZ(0);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg) translateZ(0);
}
#menu-button.close > span:after {
	bottom: 22px;

	-webkit-transform: rotate(-45deg) translateZ(0);
	    -ms-transform: rotate(-45deg);
	        transform: rotate(-45deg) translateZ(0);
}

#menu-button a {
	font-size: 0;
	line-height: 0;

	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: block;

	padding: 0;

	color: white;
	width:48px;
	height:48px;
	cursor: pointer;
}

#nav {
	margin: 0;
	padding: 0;

	background-color: transparent;
}

nav ul.container {
	margin-bottom: 0;
}


nav ul {
	/*width: 100%;*/
	margin: 0;
	padding: 0;
}

nav ul li {
	/*overflow: hidden;*/

	max-height: none;

	transition: all 1s ease 0s;

	-mob-transition: all 1s ease 0s;
	position:relative;
}

nav a {
	// font-weight: bold;

	// display: block;
	/*margin-bottom: 10px;*/

	// padding: 16px;

	// text-align: center;
	// text-decoration: none;
	/*color: white;*/
	/*background: #CCCCCC;*/
}

nav a:hover,
nav a:visited {
	/*color: white;*/
}

nav a:hover {
	text-decoration: underline;
}

nav ul li ul {
	position: absolute;

	display: block;
}



/*-------------------------------------------------------*/
/*- MEDIA QUERIES                                       -*/
/*-------------------------------------------------------*/

/*-------------------------------------------------------*/
/*- 1170 Desk Wide                                      -*/
/*-------------------------------------------------------*/

@media only screen and (max-width: 1170px) {

nav {

/*width: 38%;*/
}

}
/*-------------------------------------------------------*/
/*- 830                                                 -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 830px) { 
	
}

/*-------------------------------------------------------*/
/*- 768 Tab Portrait                                    -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 767px) {
	/* ====================
INTERMEDIATE: Menu
==================== */

	.flex-nav {
		-webkit-flex-wrap  : wrap;
		-ms-flex-wrap      : wrap;
		flex-wrap          : wrap;
	}
	
	.flex-nav .logo {
		-webkit-align-self : center;
		-ms-flex-item-align: center;
		align-self         : center;
	}

	.flex-ul {
		flex-direction        : column;
		
		-webkit-flex-grow     : 2;
		-ms-flex-positive     : 2;
		flex-grow             : 2;
		-webkit-flex-direction: column;
		-ms-flex-direction    : column;
	}
	.flex-ul li {
		-webkit-flex-grow     : 1;
		-ms-flex-positive     : 1;
		flex-grow             : 1;
	}



	nav a {
		/*float: left;
		width: 27%;
		margin: 0 1.7%;
		padding: 25px 2%;
		margin-bottom: 0;*/
	}

	nav ul {min-width: 100%;}

	nav li {
		display: inline-block;
		float: left;

		width: 100%;
	}

	nav li:first-child a {
		margin-left: 0;
	}

	nav li:last-child a {
		margin-right: 0;
	}

	nav ul.open li {
		max-height: 600px;
	}

	nav ul li {
		display: inline;
		overflow: hidden;
		max-height: 0;

		transition: all .8s ease 0s;

		-mob-transition: all .8s ease 0s;
	}

	.oldie nav a {
		margin: 0 .7%;
	}

	nav ul li ul {
		position: relative;

		display: inline-block;
	}

	.ie9 .flex-nav .logo {display:inline-block; float:left;}
	.ie9 .flex-ul {min-width: 100%; display: none; clear: both; overflow: hidden;}
	.ie9 #menu-button {
		display: inline-block;
		float: right;
	}

	.ie9 .flex-ul.open {display:inline-block;}
}


/*-------------------------------------------------------*/
/*- 720                                                 -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 720px) {

}

/*-------------------------------------------------------*/
/*- 630                                                 -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 630px) {

}

/*-------------------------------------------------------*/
/*- 568 Mobile                                          -*/
/*-------------------------------------------------------*/



@media only screen and (max-width: 568px) {
	.flex-nav {
		padding: 8px;
	}
}


@media only screen and (max-width: 320px) {
	/*.flex-nav {padding:4px;}*/
}
