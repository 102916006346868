

.faq-accordion {
    //border: solid 2px $faq-bordercolor;
    .accordion-item {
        .faq-icon {
            position: absolute;
            top: 14px;
            left: 20px;

            display: block;

            width: 0;
            height: 8px;

            transition: all .3s;

            opacity: 0;
        }
        > .accordion-trigger {
            position: relative;
            display: block;
            background-color: $faq-trigger-bg;
            padding: 16px 64px 16px 32px;//Allow for open-close-icon
            font-size: 22px;
            font-weight: 300;
            line-height: 2.54;
            color:#fff;
            margin-bottom:2px;

			&:before{
                content: '';
                width: 28px;
                height: 28px;
                bottom: 0;
                border-radius: 50%;
                right: 15px;
                top: 15px;
                position: absolute;
                z-index: 1;
                background: none;
                background-image: url(../img/open-icon-faq.svg);
			}
        }
        > .accordion-content {
            .accordion-trigger:after{
                background-color:$accent-primary;
                height: 40px;
                right: 15px;
                width:45px;
                top: 10px;
            }
            .accordion-trigger:before{
                display:none;
            }
            ul {
                box-sizing: border-box;
                padding: 20px 50px 15px 30px;

                //list-style: none;

                @include media('<=phoneland') {
                    padding: 20px 40px 15px 25px;
                }
                li {
                    font-size: 14px;
                    line-height: 18px;

                    color: $body-text;
                }
            }
            p {
                display:inline;
            }
        }
        &.accordion-item-open {
            > .accordion-trigger {
                background-color: $faq-trigger-bg_open;
	
                &:before {
                    background-image: url('../img/close-icon-faq.svg');
                }

            }
            .accordion-item-open > .accordion-trigger:after{
                background-color:$bluish-grey;
            }

            .accordion-item-open > .accordion-trigger .icon-arrow-right{
                transform:rotate(-90deg);
            }
            .faq-icon {
                width: 12px;

                opacity: 1;
            }
            > .accordion-content {
                padding: 16px 0 16px 26px;
                // background-color: $faq-content-trigger-bg_open;

            }
        }
    }

    /********************* Rewrited Default Accordion Styles **************************/
    .accordion-content {
        overflow: hidden;

        max-height: 0;

        // transition: max-height 2.3s ease-out, opacity .3s ease-out;

        opacity: 0;
    }
    .accordion-item-open > .accordion-content {
        max-height: 2400px;

        // transition: max-height 3.6s ease-out, opacity .5s ease-out;

        opacity: 1;
    }
}



    
.accordion .accordion-item{

    .accordion-trigger{
        padding-left:0;
        .accordion-title{
            padding-left:26px;
            
            font-family: sofia-pro, sans-serif;
            font-size: 24px;
            font-weight: 300;
            line-height: 1.13;
            letter-spacing: -0.2px;
            color: #fff;

                @include media("<=tabport") {
                font-size: 16px;
                line-height: 1.25em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left:20px;
            }
            
        }
        
    }
}


.accordion{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 3px;

    .accordion-item {
        scroll-margin-top: 158px;
        .accordion-trigger{
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: space-between;
            align-content: space-between;
            // background-color: #7f8c94;


            padding: 0 0 0 28px;

            @include media("<=tabport") {
                padding: 0 61px 0 15px;
            }

            .accordion-title {
                height: 57px;
                padding: 6px 0;
                font-size: 26px;
                line-height: 1.69;
                letter-spacing: 0.4px;
                display: inline-block;
                color: $white;
                flex-basis:100%;
            }            
        }

        .accordion-content {
            width: 100%;
            background-color: transparent;
            position: relative;
            transition: max-height .5s ease-out, opacity .3s ease-out, padding .3s ease, border 0s linear 0.5s;

            .accordion-main-content{
                .inner-accordion{
                    .accordion-item{
                       
                        &:last-of-type {
                            .accordion-trigger{
                                border-bottom:solid 1px $faq-bordercolor;
                            }
                        }

                        .accordion-trigger{
                            background-color: transparent;
                            font-size: 20px;
                            line-height: 1.1;
                            letter-spacing: normal;
                            color: #494a4d;
                            display:block;
                            
                            padding: 16px 24px;
                            border: solid 1px $faq-bordercolor;
                            border-bottom:0;
                            margin-bottom:0;
                            &:after, &:before{
                                content: '';
                                display: block;
                                background: #979797;
                                width: 1px;
                                height: 8px;
                                position: absolute;
                                top: 50%;
                                margin-top: -3.6px;
                            }
                            &:before{
                                transform: rotate(-55deg);
                                transform-origin: center;
                                right:23px;
                            }
                            &:after{
                                transform: rotate(55deg);
                                right:28px;
                            }
                        }

                        > a{
                            border: none;
                            display:flex;
                            flex-direction: row;
                            align-items: stretch;
                            justify-content: space-between;
                            align-content: space-between;
                            position:relative;

                            &:after{
                                @include media("<=tabport") {
                                    bottom: -4px;
                                    top: unset;
                                }
                            }

                            @include media("<=tabport") {
                                padding: 0 15px 0 15px;
                                width: 100%;
                                flex-wrap: wrap;
                                flex-flow: row-reverse wrap;
                            }
                        }
                        
                    

                        .accordion-content {
                            border: 0 none;
                            padding: 0 60px;
                            background-color: #f9f9f9;
                        }

                        &.accordion-item-open{
                            .accordion-trigger{
                                &:before{
                                    transform: rotate(55deg);
                                }
                                &:after{
                                    transform: rotate(-55deg);
                                }
                            }
                            .accordion-content{
                                transition: max-height .5s ease-out, opacity .3s ease-out, padding .3s ease, border 0s linear 0s;
                                padding: 16px 60px;
                                border: solid 1px $faq-bordercolor;
                                margin-bottom:2px;
                                @include media("<=tabport") {
                                    padding: 20px 15px 20px 15px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.accordion-item-open{
            // .accordion-trigger{
            //     background-color: $azure;
            //     .accordion-title {
            //         color: $white;
            //     }
            // }

            .accordion-content{
                // transition: max-height .5s ease-out, opacity .3s ease-out, padding .3s ease, border 0 linear 0.5s;
                padding: 20px 0;
                &.outer-accordion-content {
                    max-height: unset;
                    padding:0;
                }
            }
        }
    }
}

.faq-q, .faq-a{
    color:#5185b5;
}



